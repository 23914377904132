import { graphql, useStaticQuery } from "gatsby";
import React from 'react';
import City from '../City';

export default function LargeCities() {

    const { allFile } = useStaticQuery(graphql`
    query SmallCitiesQuery {
        allFile(filter: {sourceInstanceName: {eq: "smallcities"}}) {
            nodes {
              childMarkdownRemark {
                frontmatter {
                  punktove {
                    address
                    info
                    mapembed
                    phonenumber
                    title
                  }
                }
              }
            }
        }
    }`);

    const smallCities = allFile.nodes;

    return (
        <>
            {smallCities.map((city, idx) => {
                return (
                    <City
                        key={idx}
                        tabKey={idx}
                        smallCityDeliveryPoints={city.childMarkdownRemark.frontmatter.punktove}
                    />
                )
            })}
        </>
    )
}
