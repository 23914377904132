import React from 'react'

export default function Hero() {
    return (
        <div className="hero">
            <div className="cm-hero-container w-container">
                <h1 className="hero-heading">Пълноценна храна<br />за вашето дете</h1>
                <p className="paragraph hero-p">
                ДМК &quot;Пълно Коремче&quot; осигурява пълноценна и здравословна храна за
                вашите малчугани, доставена по най-удобния за клиента начин, 6 дни в
                седмицата до 12:30 часа.
                </p>
                <div className="btn-holder">
                <a href="#daily-menu" className="cm-cta-btn">дневно меню</a
                ><a href="#punktove" className="cm-cta-btn todays-menu">пунктове</a>
                </div>
            </div>
        </div>
    )
}
