import PropTypes from "prop-types"
import React from "react"

export default function MenuTitle({ date, onPrint, menuName }) {
  return (
    <div className="menu-tag-holer">
      <img src="images/tomato.svg" width="100" alt="" className="image-2" />
      <h1 className="cm-menu-h" id="cmSelectedMenuDateWrapper">
        {menuName} Меню за <span id="cmSelectedMenuDate">{date}</span>
      </h1>
      <img
        id="printMenuBtn"
        onClick={onPrint}
        src="images/print.svg"
        width="45"
        height="45"
        alt=""
        className="image-2"
      />
    </div>
  )
}

MenuTitle.propTypes = {
  date: PropTypes.string.isRequired,
  onPrint: PropTypes.func,
}
