import PropTypes from 'prop-types'
import React from 'react'

export default function District({ name }) {
    return (
        <div
            data-w-id="ab6b4731-6045-319b-3b01-e354f9990090"
            className="accordion-trigger"
        >
            <h3 className="kvartal-heading">{name}</h3>
            <img
                src="images/keyboard_arrow_down-24px.svg"
                width="30"
                style={{
                    'WebkitTransform': `translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(180DEG) skew(0, 0)`,
                    'MozTransform': `translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(180DEG) skew(0, 0)`,
                    'msTransform': `translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(180DEG) skew(0, 0)`,
                    transform: `translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(180DEG) skew(0, 0)`
                }}
                alt=""
                className="dropdown-arrow"
            />
        </div>
    )
}

District.propTypes = {
    name: PropTypes.string.isRequired
}
