import PropTypes from 'prop-types';
import React from 'react';
import PriceListRow from '../PriceListRow';

export default function PriceList({ list, type }) {

    const heading = type === 'point' ? 'Получаване в пункт' : 'Разнос';
    const icon = type === 'point' ? 'images/store_mall_directory-24px.svg' : 'images/local_shipping-24px.svg'

    return (
        <div className="pricelist-wrapper">
            <div className="pricelist-heading">
                <img
                    src={icon}
                    alt=""
                    className="pricing-receive-image"
                />
                <h3 className="heading-2">{heading}</h3>
            </div>
            <div className="punkts">
                {list.map((row, idx) => {
                    return (
                        <PriceListRow key={idx} row={row.childMarkdownRemark.frontmatter} />
                    )
                })}
            </div>
        </div>
    )
}

PriceList.propTypes = {
    list: PropTypes.array.isRequired
}

