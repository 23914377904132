import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import DeliveryInfo from './DeliveryInfo'
import PortionIncludes from './PortionIncludes'
import PriceList from './PriceList'
import PricingInfo from './PricingInfo'

export default function PricingSection() {

    const { allFile } = useStaticQuery(graphql`
        query PricesQuery {
            allFile(filter: {sourceInstanceName: {eq: "prices"}}) {
                nodes {
                    childMarkdownRemark {
                        frontmatter {
                            id
                            price
                            pricetype
                            title
                            label
                        }
                    }
                }
            }
        }`
    );

    const priceListPerDeliveryPoint = allFile.nodes.filter((node) => node.childMarkdownRemark.frontmatter.pricetype === "Пункт")
    const priceListPerDelivery = allFile.nodes.filter((node) => node.childMarkdownRemark.frontmatter.pricetype === "Разнос")

    return (
        <div id="pricing" className="pricing-section">
            <div className="container-2 w-container">
                <PricingInfo />
                <PortionIncludes />
                <div className="prices">
                    <PriceList type="point" list={priceListPerDeliveryPoint} />
                    <PriceList type="delivery" list={priceListPerDelivery} />
                </div>
                <DeliveryInfo />
            </div>
        </div>
    )
}
