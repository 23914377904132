import React from 'react'

export default function PricingInfo() {
    return (
        <>
            <h1>Цени за стандартно меню</h1>
            <p className="cm-prices-paragraph">
                Всяко стандартно меню включва супа, основно ястие и десерт.
                <strong>Всички вариации, различни от стандартното меню се оскъпяват с 0,50 лв. </strong>Всеки порцион, доставен или взет от пункт, се получава в еднократна опаковка с изключение на пункт <strong>гр. Асеновград, където е необоходимо да носите собствени съдове.</strong>
            </p>
        </>
    )
}
