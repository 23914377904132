import PropTypes from 'prop-types';
import React from 'react';

export default function PriceListRow({ row }) {
    const { title, label, price } = row;


    return (
        <div className="punkt-row-pricing">
            <p>
                {title} {label && <span className="sobstveni-sudove">{label}</span>}
            </p>
            <p className="price-per-punkt">{price}</p>
        </div>
    )
}

PriceListRow.propTypes = {
    row: PropTypes.object.isRequired
}