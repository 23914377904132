import { graphql, useStaticQuery } from "gatsby"
import React, { useContext, useState } from "react"
import moment from "moment"

// Create Menu Context so that all menus will be accessible down the component tree
// Since this is the main menu section that means everywhere (no need to pass props)
export const MenuContext = React.createContext()

export function MenuProvider({ children }) {
  const { state } = useProvideMenu()
  return <MenuContext.Provider value={state}>{children}</MenuContext.Provider>
}

export const useMenu = () => useContext(MenuContext)

function useProvideMenu() {
  const [state, setState] = useState({
    normalMenu: useGraphQlMenu().normalMenu,
    babyMenu: useGraphQlMenu().babyMenu,
  })

  return {
    setState,
    state,
  }
}

function useGraphQlMenu() {
  // fetch all menus frontmatter
  const { babyMenu, normalMenu } = useStaticQuery(graphql`
    query MenusQuery {
      babyMenu: allFile(filter: { sourceInstanceName: { eq: "baby-menus" } }) {
        nodes {
          childMarkdownRemark {
            frontmatter {
              dateofmonth
              dayofweek
              dessert {
                allergens
                ingredients
                title
              }
              maincourse {
                allergens
                ingredients
                title
              }
              soup {
                allergens
                ingredients
                title
              }
            }
          }
        }
      }

      normalMenu: allFile(filter: { sourceInstanceName: { eq: "menus" } }) {
        nodes {
          childMarkdownRemark {
            frontmatter {
              dateofmonth
              dayofweek
              dessert {
                allergens
                ingredients
                title
              }
              maincourse {
                allergens
                ingredients
                title
              }
              soup {
                allergens
                ingredients
                title
              }
            }
          }
        }
      }
    }
  `)

  return {
    babyMenu: sortNodes(babyMenu.nodes),
    normalMenu: sortNodes(normalMenu.nodes),
  }
}

function sortNodes(nodes) {
  // get all nodes from the markdown
  return nodes.sort((a, b) => {
    const d1 = a.childMarkdownRemark.frontmatter.dateofmonth.split(".")
    const d2 = b.childMarkdownRemark.frontmatter.dateofmonth.split(".")

    let firstDate = moment(`${d1[2]}-${d1[1]}-${d1[0]}`)
    let secondDate = moment(`${d2[2]}-${d2[1]}-${d2[0]}`)

    return firstDate.diff(secondDate)
  })
}
