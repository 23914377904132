import moment from "moment"
import PropTypes from "prop-types"
import React from "react"
import { MenuContext } from "../MenuContext"

export default class MenuPicker extends React.Component {
  constructor(props) {
    super(props)
    this.dropDownRef = React.createRef()
    this.state = {
      isDropdownVisible: false,
    }
  }

  toggleDropDown = () => {
    this.setState({ isDropdownVisible: !this.state.isDropdownVisible })
  }

  emitChosenDate = day => {
    this.props.onMenuChanged(day)
    this.toggleDropDown()
  }

  render() {
    return (
      <MenuContext.Consumer>
        {({ normalMenu: days }) => {
          return (
            <div className="choose-another-day">
              <div className="drop-down w-drop-down">
                <div
                  className="drop-down-toggle w-drop-down-toggle"
                  onClick={this.toggleDropDown}
                >
                  <div className="cm-drop-down-text">
                    Прегледайте меню за друг ден{" "}
                  </div>
                  <div
                    className={`icon w-icon-dropdown-toggle cm-toggle-arrow ${
                      this.state.isDropdownVisible ? "arrow-rotate" : ""
                    }`}
                  ></div>
                </div>

                <nav
                  ref={this.dropDownRef}
                  className={`drop-down-list w-drop-down-list ${
                    this.state.isDropdownVisible ? "w--open" : ""
                  }`}
                >
                  {days
                    .filter(day => {
                      const now = moment().set({
                        hour: 0,
                        minute: 0,
                        second: 0,
                        millisecond: 0,
                      })
                      const parsedDate = moment(
                        day.childMarkdownRemark.frontmatter.dateofmonth,
                        "DD.MM.YYYY"
                      )
                      return moment(parsedDate).isSameOrAfter(now, "day")
                    })
                    .map((day, index) => {
                      const parsedDate = moment(
                        day.childMarkdownRemark.frontmatter.dateofmonth,
                        "DD.MM.YYYY"
                      )

                      return (
                        <button
                          key={index}
                          onClick={this.emitChosenDate.bind(this, parsedDate)}
                          className="drop-down-link w-drop-down-link cm-drl-menu"
                        >
                          Меню за {parsedDate._i}
                        </button>
                      )
                    })}
                </nav>
              </div>
            </div>
          )
        }}
      </MenuContext.Consumer>
    )
  }
}

MenuPicker.propTypes = {
  onMenuChanged: PropTypes.func.isRequired,
}

MenuPicker.contextType = MenuContext
