import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import ReactMarkdown from "react-markdown"

export default function NewsSection() {
  const { news: data, shouldDisplayNews } = useStaticQuery(graphql`
    query NewsQuery {
      news: allFile(filter: { sourceInstanceName: { eq: "news" } }) {
        nodes {
          childMarkdownRemark {
            frontmatter {
              id
              title
              content
              active
            }
          }
        }
      }
      shouldDisplayNews: allFile(
        filter: { sourceInstanceName: { eq: "alertConfig" } }
      ) {
        nodes {
          childMarkdownRemark {
            frontmatter {
              msgactive
            }
          }
        }
      }
    }
  `)

  const news = data?.nodes.filter(
    node => node?.childMarkdownRemark?.frontmatter.active
  )

  const renderNews = () =>
    news?.map(({ childMarkdownRemark }) => {
      const { title, content, id, active } = childMarkdownRemark.frontmatter
      return (
        active && (
          <article className="article" key={id}>
            <h3 className="article-heading">{title}</h3>
            <p className="paragraph-6">
              <ReactMarkdown source={content} />
            </p>
          </article>
        )
      )
    })

  if (!shouldDisplayNews.nodes[0].childMarkdownRemark.frontmatter.msgactive)
    return null

  if (news?.length === 0) return null

  return (
    <section id="news" className="news-section">
      <div className="news-container w-container">
        <h1 className="hero-heading news-title">Последни новини</h1>
        {renderNews()}
      </div>
    </section>
  )
}
