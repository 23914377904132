import moment from "moment"
import React, { useState } from "react"
import DailyMenu from "./DailyMenu"
import { useMenu } from "./MenuContext"
import MenuPicker from "./MenuPicker"
import MenuTitle from "./MenuTitle"
import PostMenuText from "./PostMenuText"
import PrintDialog from "./PrintDialog"
import WeeklyMenuList from "./WeeklyMenuList"

export default function NormalMenuSection() {
  // get today's date
  const today = moment().format("DD.MM.YYYY")
  // set the initial state for todays menu
  const [pickedDate, setPickedDate] = useState(today)
  // set the initial dialog state
  const [dialogState, setDialogState] = useState(false)

  // change the active menu depending on which date has been chosen
  const changeActiveMenu = day => {
    setPickedDate(day.format("DD.MM.YYYY"))
  }

  const toggleDialog = () => {
    setDialogState(!dialogState)
  }

  const { normalMenu } = useMenu()

  const sortedMenu = normalMenu.sort(
    (a, b) => new Date(b.menuDate) - new Date(a.menuDate)
  )

  return (
    <>
      <PrintDialog
        menuKey="normalMenu"
        toggleState={toggleDialog}
        isOpen={dialogState}
      />

      <div id="daily-menu" className="menu-section">
        <div className="cm-menu-container w-container">
          <MenuTitle
            menuName="Детско"
            onPrint={toggleDialog}
            date={pickedDate}
          />
          {/* <MenuPicker onMenuChanged={changeActiveMenu} /> */}
        </div>

        <WeeklyMenuList content={normalMenu} menuType={"normalMenu"} />

        {/* <div className="container w-container cm-drl-view">
          <div className="shitty-grid">
            {sortedMenu.map(({ childMarkdownRemark }, idx) => {
              const soup = childMarkdownRemark.frontmatter.soup
              const mainCourse = childMarkdownRemark.frontmatter.maincourse
              const dessert = childMarkdownRemark.frontmatter.dessert
              const menuDate = childMarkdownRemark.frontmatter.dateofmonth

              return (
                <span
                  key={idx}
                  style={{
                    display: menuDate === pickedDate ? `inline-block` : `none`,
                  }}
                >
                  <DailyMenu dish={soup} />
                  <DailyMenu dish={mainCourse} />
                  <DailyMenu dish={dessert} />
                </span>
              )
            })}

            <PostMenuText />
          </div>
        </div> */}
      </div>
    </>
  )
}
