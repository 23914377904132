import PropTypes from 'prop-types'
import React from 'react'
import DeliveryPoint from '../DeliveryPoint'
import District from '../District'

export default function City({ tabKey, districts, smallCityDeliveryPoints }) {
    return (
        <div
            data-w-tab={`Tab ${!districts ? tabKey + 1 : tabKey}`}
            className={`tab-pane plovdiv-pane w-tab-pane ${districts && tabKey === 0 ? 'w--tab-active' : ''}`}
        >
            {districts ? districts.map((district, idx) => {
                return (
                    <div key={idx} className="accordion-item">
                        <District name={district.title} />

                        <div style={{ height: `100%` }} className="accordion-content">
                            {/* Loop delivery points in large cities */}
                            {district.punktove.map((deliveryPoint, pointIdx) => {

                                return (
                                    <DeliveryPoint
                                        key={pointIdx}
                                        title={deliveryPoint.title}
                                        address={deliveryPoint.address}
                                        info={deliveryPoint.info}
                                        phonenumber={deliveryPoint.phonenumber}
                                        mapembed={deliveryPoint.mapembed}
                                    />
                                )
                            })}

                        </div>
                    </div>
                )
            }) : (
                    smallCityDeliveryPoints.map((deliveryPoint, pointIdx) => {
                        return (
                            <DeliveryPoint
                                key={pointIdx}
                                title={deliveryPoint.title}
                                address={deliveryPoint.address}
                                info={deliveryPoint.info}
                                phonenumber={deliveryPoint.phonenumber}
                                mapembed={deliveryPoint.mapembed}
                            />
                        )
                    })
                )

            }

        </div>
    )
}

City.propTypes = {
    tabKey: PropTypes.number.isRequired,
    districts: PropTypes.array,
    smallCityDeliveryPoints: PropTypes.array
}
