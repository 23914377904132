import { graphql, useStaticQuery } from "gatsby";
import React from 'react';
import City from '../City';

export default function LargeCities() {

    const { allFile } = useStaticQuery(graphql`
    query LargeCitiesQuery {
        allFile(filter: {sourceInstanceName: {eq: "largecities"}}) {
            nodes {
              childMarkdownRemark {
                frontmatter {
                  districts {
                    title
                    punktove {
                      address
                      info
                      phonenumber
                      mapembed
                      title
                    }
                  }
                }
              }
            }
        }
    }`);

    const largeCities = allFile.nodes;

    return (
        <>
            {largeCities.map((city, idx) => {
                return (
                    <City key={idx} tabKey={idx} districts={city.childMarkdownRemark.frontmatter.districts} />
                )
            })}
        </>
    )
}
