import React from 'react'

export default function DeliveryTagline() {
    return (
        <div id="punktove" className="punktove">
            <div className="punkt-container w-container">
                <img
                    src="images/man-delivery.png"
                    width="512"
                    srcSet="images/man-delivery-p-500.png 500w, images/man-delivery.png 1024w"
                    sizes="(max-width: 479px) 100vw, (max-width: 767px) 36vw, 280px"
                    alt=""
                    className="image-3"
                />
                <div className="punktove-holder">
                    <h1 className="heading">Пунктове</h1>
                    <p className="paragraph-3">
                        от които може дa заявите и получите нашата храна
                        </p>
                </div>
            </div>
        </div>
    )
}
