import React from "react"

export default function PortionIncludes() {
  return (
    <>
      <h3 className="heading-3">Какво се включва в един порцион:</h3>
      <div className="whats-inside">
        <div className="menu-item no-margin-left">
          <h3 className="heading-menu-item">Супа</h3>
          <img
            src="images/supichka.svg"
            width="74.5"
            alt=""
            className="menu-item-image"
          />
          <p className="ml">220 гр.</p>
        </div>
        <div className="menu-item">
          <h3 className="heading-menu-item">Основно</h3>
          <img
            src="images/osnovno.svg"
            width="74.5"
            alt=""
            className="menu-item-image"
          />
          <p className="ml">220 гр.</p>
        </div>
        <div className="menu-item no-margin-right">
          <h3 className="heading-menu-item">Десерт</h3>
          <img
            src="images/desert.svg"
            width="74.5"
            alt=""
            className="menu-item-image"
          />
          <p className="ml">220 гр.</p>
        </div>
      </div>
    </>
  )
}
