import React from "react"

export default function WeeklyMenuList({ content: days, menuType }) {
  const firstWeek = days.slice(0, 6)
  const secondWeek = days.slice(6)

  // First week
  const firstWeekFirstDay =
    firstWeek[0].childMarkdownRemark.frontmatter.dateofmonth
  const firstWeekLastDay =
    firstWeek[5].childMarkdownRemark.frontmatter.dateofmonth

  // Second week
  const secondWeekFirstDay =
    secondWeek[0].childMarkdownRemark.frontmatter.dateofmonth
  const secondWeekLastDay =
    secondWeek[5].childMarkdownRemark.frontmatter.dateofmonth

  return menuType === "normalMenu" ? (
    <>
      <div className="w-container cm-links-block-for-dummies">
        <a href="/menu-first-week" className="cm-cta-btn triggerPrint">
          Меню от {firstWeekFirstDay} до {firstWeekLastDay}
        </a>
        <a href="/menu-second-week" className="cm-cta-btn triggerPrint">
          Меню от {secondWeekFirstDay} до {secondWeekLastDay}
        </a>
      </div>
    </>
  ) : (
    <>
      <div className="w-container cm-links-block-for-dummies">
        <a href="/baby-menu-first-week" className="cm-cta-btn triggerPrint">
          Меню от {firstWeekFirstDay} до {firstWeekLastDay}
        </a>
        <a href="/baby-menu-second-week" className="cm-cta-btn triggerPrint">
          Меню от {secondWeekFirstDay} до {secondWeekLastDay}
        </a>
      </div>
    </>
  )
}
