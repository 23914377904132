import React from 'react'

export default function ContactSection() {
    return (
        <>
            <div id="contact" className="contact-us">
                <div className="container-4 w-container">
                    <h1>Контакти</h1>
                    <div className="contact-block">
                        <p id="w-node-8c65927ca62c-d14fb015" className="paragraph-4">
                            Ако имате въпроси, желаете да дадете обратна връзка или да ни похвалите,
                            не се колебайте да се свържете с нас.
                        </p>
                        <div className="address">
                            <div className="contact-row">
                                <img src="images/room-24px.svg" alt="" className="contct-image" />
                                <p className="cm-cont-pa">
                                    Асеновград, ул. &quot;Цар Иван Асен II&quot; 49<strong></strong>
                                </p>
                            </div>
                            <a href="tel:+359898306200" className="contact-row w-inline-block"
                            ><img src="images/call-24px.svg" alt="" className="contct-image" />
                                <p><strong className="cm-cont-pa">+359 898 306 200</strong></p></a
                            >
                        </div>
                    </div>
                </div>
            </div>
            <div className="cm-map w-embed w-iframe">
                <iframe
                    title="contact"
                    data-src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2964.457361121646!2d24.87786791544538!3d42.011919979211825!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14acd8eb29743b9d%3A0x7e918824736c4e6b!2sul.%20%22Tsar%20Ivan%20Asen%20II%22%2049%2C%204230%20Tsentar%2C%20Asenovgrad!5e0!3m2!1sen!2sbg!4v1570444313426!5m2!1sen!2sbg"
                    width="100%"
                    height="400"
                    frameBorder="0"
                    style={{ border: 0 }}
                ></iframe>
            </div>
        </>
    )
}
