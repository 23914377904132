import PropTypes from 'prop-types'
import React from 'react'

export default function DeliveryPoint({
    title, address, phonenumber, info, mapembed
}) {
    return (
        <div className="punkt">
            <div className="punkt-info">
                <div className="punkt-holder">
                    <img src="images/storefront-24px.svg" alt="" />
                    <h4 className="punkt-name">{title}</h4>
                </div>
                <div className="punkt-holder">
                    <img src="images/room-24px.svg" alt="" />
                    <h4 className="punkt-meta">{address}</h4>
                </div>
                {
                    phonenumber && (
                        <div className="punkt-holder">
                            <img src="images/call-24px.svg" alt="" />
                            <h4 className="punkt-meta">{phonenumber}</h4>
                        </div>
                    )
                }


                {
                    info && (
                        <div className="punkt-holder">
                            <img src="images/info-24px.svg" alt="" />
                            <h4 className="punkt-meta">{info}</h4>
                        </div>
                    )
                }


            </div>
            {
                mapembed && (
                    <div className="html-embed w-embed w-iframe">
                        <iframe
                            title="UUID"
                            data-src={mapembed.match(/src\s*=\s*"(.+?)"/)[1]}
                            width="100%"
                            height="100%"
                            frameBorder="0"
                            style={{ border: 0 }}
                        ></iframe>
                    </div>
                )
            }

        </div>
    )
}


DeliveryPoint.propTypes = {
    title: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    phonenumber: PropTypes.string,
    info: PropTypes.string,
    mapembed: PropTypes.string,
}