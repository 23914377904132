import { graphql, useStaticQuery } from "gatsby";
import React from 'react';

export default function CitiesList() {

    const { allFile } = useStaticQuery(graphql`
    query AllCityNamesQuery {
        allFile(filter: {sourceInstanceName: {regex: "/smallcities|largecities/"}}) {
            nodes {
              childMarkdownRemark {
                frontmatter {
                  title
                }
              }
            }
        }
    }`);

    const cities = allFile.nodes;

    const scrollToSection = () => {
        document.getElementById('punktove').scrollIntoView();
    }

    return (
        <div className="tabs-menu w-tab-menu">
            {cities.map((city, idx) => {
                return (
                    <button
                        onClick={scrollToSection}
                        key={idx}
                        data-w-tab={`Tab ${idx}`}
                        style={{ outline: 0 }}
                        className={`cm-tab-link first w-inline-block w-tab-link ${idx === 0 ? 'w--current' : null}`}
                    >
                        <div>{city.childMarkdownRemark.frontmatter.title}</div>
                    </button>
                )
            })}
        </div>
    )
}
