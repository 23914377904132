import React from 'react';
import CitiesList from './CitiesList';
import DeliveryTagline from './DeliveryTagline';
import LargeCities from "./LargeCities";
import SmallCities from "./SmallCities";

export default function DeliverySection() {
    return (
        <>
            <DeliveryTagline />
            <div className="punktove-map">
                <div className="w-container">
                    <div data-duration-in="300" data-duration-out="200" className="tabs w-tabs">
                        <CitiesList />
                        <div className="tabs-content w-tab-content">
                            <LargeCities />
                            <SmallCities />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
