import PropTypes from "prop-types"
import React from "react"
import { MenuContext } from "../MenuContext"

export default class PrintDialog extends React.Component {
  dontAllowClick = e => e.stopPropagation()

  showPrintPreview = iframeRefName => {
    this.refs[iframeRefName].contentWindow.print()
  }

  render() {
    const { isOpen, toggleState, menuKey } = this.props

    const days = this.context[menuKey]

    const firstWeek = days.slice(0, 6)
    const secondWeek = days.slice(6)

    // First week
    const firstWeekFirstDay =
      firstWeek[0].childMarkdownRemark.frontmatter.dateofmonth
    const firstWeekLastDay =
      firstWeek[5].childMarkdownRemark.frontmatter.dateofmonth

    // Second week
    const secondWeekFirstDay =
      secondWeek[0].childMarkdownRemark.frontmatter.dateofmonth
    const secondWeekLastDay =
      secondWeek[5].childMarkdownRemark.frontmatter.dateofmonth

    return (
      <div id="dialog" onClick={toggleState} className={`${isOpen && "open"}`}>
        <div onClick={this.dontAllowClick} className="dialog-content">
          <h2>Изберете кое меню желаете да отпечатате</h2>
          <div className="print-choice">
            <button
              className="cm-cta-btn triggerPrint"
              onClick={this.showPrintPreview.bind(this, "firstWeekMenu")}
            >
              от {firstWeekFirstDay} до {firstWeekLastDay}
            </button>

            <button
              className="cm-cta-btn triggerPrint"
              onClick={this.showPrintPreview.bind(this, "secondWeekMenu")}
            >
              от {secondWeekFirstDay} до {secondWeekLastDay}
            </button>
          </div>

          {menuKey === "normalMenu" && (
            <>
              <iframe
                ref="firstWeekMenu"
                title="menu-week-one"
                id="menu-week-one"
                data-src="/menu-first-week"
                width="0"
                height="0"
                frameBorder="0"
                style={{ border: 0 }}
              ></iframe>
              <iframe
                ref="secondWeekMenu"
                title="menu-week-two"
                id="menu-week-two"
                data-src="/menu-second-week"
                width="0"
                height="0"
                frameBorder="0"
                style={{ border: 0 }}
              ></iframe>
            </>
          )}
          {menuKey === "babyMenu" && (
            <>
              <iframe
                ref="firstWeekMenu"
                title="baby-menu-week-one"
                id="baby-menu-week-one"
                data-src="/baby-menu-first-week"
                width="0"
                height="0"
                frameBorder="0"
                style={{ border: 0 }}
              ></iframe>
              <iframe
                ref="secondWeekMenu"
                title="baby-menu-week-two"
                id="baby-menu-week-two"
                data-src="/baby-menu-second-week"
                width="0"
                height="0"
                frameBorder="0"
                style={{ border: 0 }}
              ></iframe>
            </>
          )}
        </div>
      </div>
    )
  }
}
PrintDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleState: PropTypes.func.isRequired,
}

PrintDialog.contextType = MenuContext
