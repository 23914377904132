import React from "react"

export default function AboutUsSection() {
  const partners = [
    {
      src: "/images/bebo_logo.png",
      href: "https://bebo-bg.com/",
      alt: "Бебо",
    },
  ]

  return (
    <div className="about-us">
      <div className="container-3 w-container">
        <h1>За Нас</h1>
        <div className="rich-text-block w-richtext">
          <p>
            Детска Млечна кухня &quot;Пълно Коремче&quot; - гр. Асеновград,
            предлага на своите клиенти едно улеснение в забързаното ежедневие.
            Нашата цел е да осигурим здравословното и пълноценно хранене за
            вашите деца. Менютата са изготвени по съответните хранителни режими,
            които осигуряват нормалното развитие на децата и ги предпазват от
            различни алергични заболявания и затлъстяване.
            <br />
            <br />
            Менютата се изготвят по рецептурник и са удобрени от медицинско лице
            - Христина Атанасова Любомирова(мед. сестра - специалност
            педиатрия). Прецизния подбор на продуктите и суровините, от които се
            приготвя храната са базирани на наредби №6 от 10 август 2011год. за
            здравословното хранене на децата в детските заведения и №2 от 7 март
            2013год. за здравословното хранене на децата от 0 до 3 години в
            детските заведения и детските кухни.
            <br />
            <br />
            ДМК &quot;Пълно Коремче&quot; осигурява пълноценна и здравословна
            храна за вашите малчугани, доставена по най-удобния за клиента
            начин, 6 дни в седмицата до 12:30 часа. ДМК &quot;Пълно
            Коремче&quot; има разрешение за работа № 20710 / 13.12.2018год.
            издадено от областна дирекция по безопастност на храните - гр.
            Пловдив.
            <br />
            <br />
            Нашата фирма работи, чрез изпълнение програма НАССР, включваща добри
            производствени и хигиенни практики за контрол и самоконтрол на
            храните и целия подготвителен и производствен процес, имащи за цел
            избягването на всички рискове, свързани с безопасността на храните.
            <br />
            <br />
            Нашият екип предлага индивидуални съвети, свързани със спецификата
            на детското хранене и в частност на вашето дете. ДМК &quot;Пълно
            Коремче&quot; предлага изключване на мляко, яйца и риба от
            стандартното меню за деца, които не са захранени с тях.
          </p>
        </div>
        <h1>Партньори</h1>
        <div className="partners">
          {partners.map((partner, idx) => {
            let c = idx + 1

            return c % 4 === 0 ? (
              <React.Fragment key={idx}>
                <a
                  href={partner.href}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={partner.src} alt={partner.alt} />
                </a>
                <div className="flex-break"></div>
              </React.Fragment>
            ) : (
              <a
                href={partner.href}
                target="_blank"
                rel="noopener noreferrer"
                key={idx}
              >
                <img src={partner.src} alt={partner.alt} />
              </a>
            )
          })}
        </div>
      </div>
    </div>
  )
}
