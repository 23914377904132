import React from 'react'

export default function DeliveryInfo() {
    return (
        <div className="rich-text-block-2 w-richtext">
            <p>
                * Разноса в град Пловдив включва и кварталите Коматево и Прослав.<br />* Разноса в област Пловдив включва гр. Куклен, с. Катуница с.Ягодово, с.Крумово, с.Златитрап, с.Браниполе, с.Първенец, с. Марково, с.Брестовица и с. Войводиново.<br />* Разноса в град Асеновград включва и кв. Долно Воден
            </p>
        </div>
    )
}
